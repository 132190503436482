import React, { useEffect, useState } from "react";
import Menu from "../components/Menu/Menu";
import Footer from "../components/Footer/Footer";
import Toast from "../components/Toast/Toast";
import { Col, Form, Row } from "react-bootstrap";
import Button from "@mui/material/Button";
import {
  getWppCredentials,
  updateWppCredentials,
} from "../services/SettingsService";

function WhatsAppSettings() {
  const [notification, setNotification] = useState({ type: "", text: "" });
  const [dataWpp, setDataWpp] = useState({});

  const wppOnChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setDataWpp({ ...dataWpp, [name]: value });
  };

  const onSubmit = async () => {
    const token = localStorage.getItem("token");
    let trueOrFalse = window.confirm("Deseja Salvar as alterações ?");
    if (trueOrFalse) {
      const response = await updateWppCredentials(dataWpp, token);
      console.log("the response is ", response);
      response === String("OK") &&
        setNotification({
          type: "success",
          text: "Alterações salvas com sucesso!",
        });
    }
  };

  // useEffect(async () => {
  //   const token = localStorage.getItem("token");
  //   // fazer get pra pegar valor do back
  //   try {
  //     const response = await getWppCredentials(token);
  //     console.log(response)
  //     setDataWpp({ url: response.url , token: response.token, token_seg: response.token_seg });
  //   } catch (error) {
  //     alert(error.data);
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Configurações Whatsapp</h2>
          </div>
        </div>
        <div className="bg-white rounded shadow p-5 mb-4 mt-4">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{`URL`}</Form.Label>
                <Form.Control
                  name="url_wpp"
                  onChange={wppOnChange}
                  value={dataWpp?.url}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{`TOKEN`}</Form.Label>
                <Form.Control
                  name="token_wpp"
                  onChange={wppOnChange}
                  value={dataWpp?.token}
                  type="text"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>{`TOKEN_SEGURANÇA`}</Form.Label>
                <Form.Control
                  name="token_seg_wpp"
                  onChange={wppOnChange}
                  value={dataWpp?.token_seg}
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button variant="contained" onClick={onSubmit}>
                Salvar
              </Button>
            </div>
          </Row>
        </div>

        <Footer />
      </main>

      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default WhatsAppSettings;
