import axios from "./BaseService";

const SETTINGS_URL = `${process.env.REACT_APP_API_URL}/settings`;

export async function getSettings() {
  const response = await axios.get(SETTINGS_URL);
  return response.data;
}

export async function updateSettings(settings) {
  const response = await axios.patch(SETTINGS_URL, settings);
  return response.data;
}

export async function getActiveSettings() {
  const response = await axios.get(`${SETTINGS_URL}active`);
  return response.data;
}

export async function getSetting(search, page) {
  const usersUrl = `${SETTINGS_URL}${search ? search : ""}?page=${page}`;

  const response = await axios.get(usersUrl);
  return response.data; //{count, rows}
}

export async function saveSettings(id, newSettings) {
  let response;
  if (id) response = await axios.patch(`${SETTINGS_URL}${id}`, newSettings);
  else response = await axios.post(SETTINGS_URL, newSettings);
  return response.data;
}

export async function updateWppCredentials(settings = {}, token = "") {
  const headers = { authorization: token };
  try {
    const response = await axios.post(
      `http://localhost:3001/settings/wpp`,
      settings,
      { headers }
    );
    return response.data;
  } catch (error) {
    alert(error.data);
  }
}

export async function getWppCredentials(token = "") {
  const headers = { authorization: token };
  try {
    const response = await axios.get(`http://localhost:3001/settings/wpp`, {
      headers,
    });
    return response.data;
  } catch (error) {
    alert(error.data);
  }
}

export async function resetSettingPassword(id) {
  const response = await axios.post(`${SETTINGS_URL}${id}/reset`, {});
  return response.data;
}
