import React from 'react';

/**
 * props:
 * - data
 * - onEditClick
 */
function EventoRow(props) {

    function getActiveClass(isActive) {
        return isActive ? "text-success" : "text-danger";
    }

    function getActiveText(isActive) {
        return isActive ? "RUNNING" : "STOPPED";
    }
  
    return (
        <tr>
            <td>
                {props.data.id}
            </td>
            <td>
                {props.data.evento}
            </td>
            <td>
                {props.data.datainicio}
            </td>
            <td>
                {props.data.datafim}
            </td>
            <td>
                {props.data.dataCad}
            </td>
        </tr>
    )
}

export default EventoRow;