import axios from './BaseService';

const EVENTOS_URL = `${process.env.REACT_APP_API_URL}/eventos`;

export async function getEventos(page,proximo, token) {
    let eventosUrl;
    if ((page !== '' && page !== undefined ) || (proximo !== ''  && proximo !== undefined )){
        eventosUrl = `${EVENTOS_URL}?page=${page}&proximo=${proximo}`;
    }
    else {
        eventosUrl = `${EVENTOS_URL}`;
    }
    const headers = { 'authorization': token };
    const response = await axios.get(eventosUrl, { headers });
    return response.data;//{count, rows}
}


export async function getEvento(id, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${EVENTOS_URL}${id}`, { headers });
    return response.data;
}

export async function saveEvento(id, newEvento, token) {
    const headers = { 'authorization': token };
    let response;  
    if (id)
        response = await axios.patch(`${EVENTOS_URL}${id}`, newEvento, { headers });
    else
        response = await axios.post(EVENTOS_URL, newEvento, { headers });
    return response.data;
}

export async function deleteEvento(id, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${EVENTOS_URL}${id}`, { headers });
    return response.data;
}
