import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import EventoRow from './EventoRow';
import { getEventos,  deleteEvento } from '../../services/EventoService';
import Pagination from '../../components/Pagination/Pagination';
import EventoModal from './EventoModal/EventoModal';
import Toast from '../../components/Toast/Toast';
import NewEventoButton from './NewEventoButton';


function Eventos() {

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page') || '1';
    }

    const history = useHistory();
    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    const [eventos, setEventos] = useState([]);

    const [editEvento, setEditEvento] = useState([]);

    const [count, setCount] = useState(0);

    const [page, setPage] = useState(getPage());

    const [notification, setNotification] = useState({});
 

    

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        }) 
    }, [history])



    useEffect(() => {
        const token = localStorage.getItem("token");
        getEventos(page || 1,'', token)
            .then(result => {
                setEventos(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        const evento = eventos.find(m => m.id === id);
        setEditEvento({ ...evento });
    }



    function onDeleteClick(event) {
        const id = event.target.id.replace('delete', '');
        const token = localStorage.getItem('token');
        deleteEvento(id, token)
            .then(evento => { history.go(0) })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }

    function onEventoSubmit(order) {
        history.go(0);
    }

    function onNewEventoClick(event) {
        setEditEvento('');
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Eventos</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewEventoButton onClick={onNewEventoClick} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">ID</th>
                                <th className="border-gray-200">Evento</th>
                                <th className="border-gray-200">Data Inicío</th>
                                <th className="border-gray-200">Data Fim</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                eventos && eventos.length
                                    ? eventos.map(evento => (<EventoRow key={evento.id} data={evento} onEditClick={onEditClick}  onDeleteClick={onDeleteClick} />))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <EventoModal data={editEvento} onSubmit={onEventoSubmit} />             
            <Toast type={notification.type} text={notification.text} />
              
        </React.Fragment>
    );
}

export default Eventos;