import React from 'react';

/**
 * props:
 * - data
 * - onEditClick
 */
function EstadoRow(props) {

    return (
        <tr>
            <td>
                {
                    props.data.uf 
                        ?   <button id={"edit" + props.data.uf} type="button" className="btn btn-secondary btn-xs ms-2" title="Editar Estado" data-bs-toggle="modal" data-bs-target="#modalEstado" onClick={props.onEditClick}>                            
                                <svg id={"edit" + props.data.uf} className="icon icon-xs" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
                                </svg>
                            </button>
                        : <React.Fragment></React.Fragment>
                }
            </td>
            <td>
                {props.data.uf}
            </td>
            <td>
                {props.data.nome}
            </td>
            <td>
                {props.data.regiao}
            </td>
        </tr>
    )
}

export default EstadoRow;