import React, { useRef, useState, useEffect } from 'react';
import { saveEvento } from '../../../services/EventoService';
import DateFilter from '../../../components/DateFilter/DateFilter';
import Toast from '../../../components/Toast/Toast';
import { format } from 'date-fns'

/**
 * props:
 * - data
 * - onSubmit
 */
function EventoModal(props) {

    function getDate(timestamp) {
        const date = timestamp ? new Date(timestamp) : new Date();
        const frm = new Intl.DateTimeFormat('en-GB').format(date);
        return frm;
    }

    const [date, setDate] = useState('');

    const dataInicioRef = useRef('');
    const dataFimRef = useRef('');

    const dateInputRef = useRef(null);
    
    const handleChange = (e) => {
        setDate(e.target.value);
    };

    /*
    useEffect(() => {
        new window.Datepicker(document.getElementById("dataInicio"), {
            buttonClass: 'btn',
            format: 'dd/mm/yyyy'
        });

        new window.Datepicker(document.getElementById("dataFim"), {
            buttonClass: 'btn',
            format: 'dd/mm/yyyy'
        });
    }, [])
    */
    function FormataStringData(data) {
        var dia  = data.split("/")[0];
        var mes  = data.split("/")[1];
        var ano  = data.split("/")[2];
      
        console.log(FormataStringData('02/03/2018'), ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2));
        return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2);
        // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
       
      }
      
      
      

    function onDateChange(event) {
        console.log('Data Inicio ',FormataStringData(event.target.value), FormataStringData(event.target.value.dataInicio));
        
        setEventoReg(prevState => ({ ...prevState, dataInicio: FormataStringData(event.target.value.dataInicio), dataFim: event.target.value.dataFim }));
    }

    useEffect(() => {
       // dataInicioRef.current.value = props.dataInicio ? props.dataInicio : getDate(Date.now() - (30 * 24 * 60 * 60 * 1000));
       // dataFimRef.current.value = props.data.dataFim ? props.data.dataFim : getDate();
    }, [props.data.dataInicio, props.data.dataFim])

    function parseDate(str) {
        const split = str.split('/');
        return new Date(`${split[1]}/${split[0]}/${split[2]}`);
    } 


    const DEFAULT_EVENTO = {
        id: 0,
        evento: '',
        dataInicio: null,
        dataFim: null,
        dataCad: getDate()

    }

    const [error, setError] = useState('');

    const [eventoReg, setEventoReg] = useState(DEFAULT_EVENTO);
    const [notification, setNotification] = useState({});

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        if ((eventoReg.dataInicio || eventoReg.dataFim) //|| (eventoReg.evento )
            && (eventoReg.dataInicio.value >= eventoReg.dataFim.value ))
            return setNotification({ type: 'error', text: `A Data Inicio deve ser Menor que a Data Fim.` });

        const token = localStorage.getItem('token');
        console.log('Salva evento ', eventoReg);
        saveEvento(eventoReg.id, eventoReg, token)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.message);
            })
    }

    function onInputChange(event) {
        setEventoReg(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        setEventoReg(props.data);
    }, [props.data.id])

    
    useEffect(() => {
        const modal = document.getElementById('modalEvento');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setEventoReg({ ...DEFAULT_EVENTO });
        })
    }, [])


    return (
        <div className="modal fade" id="modalEvento" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.id ? 'Edit ' : 'New '}Evento</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                    <label htmlFor="id">ID</label>
                                        <input className="form-control" id="id" type="number" placeholder="ID" value={eventoReg.id} onChange={onInputChange} />
                                    </div>
                                </div>   
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                    <label htmlFor="evento">Evento</label>
                                        <input className="form-control" id="evento" type="text" placeholder="Name Event" value={eventoReg.evento} onChange={onInputChange} />
                                    </div>
                                </div>   
                            </div>
                        </div>

                        <div className="form-group">    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="dataInicio">Data Inicio:</label>
                                        <input ref={dataInicioRef} data-datepicker="" className="form-control datepicker-input" id="dataInicio" type="texts" placeholder="dd/mm/yyyy" value={eventoReg.dataInicio} onChange={onDateChange}  />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="dataFim">Data Fim:</label>
                                        <input ref={dataFimRef} data-datepicker="" className="form-control datepicker-input" id="dataFim" type="date" placeholder="yyyy-MM-dd" value={eventoReg.dataFim} onChange={onDateChange} />
                                       
                                    </div>
                                </div>
                                
                            </div>
                        </div>

                        <div className="form-group"> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="dataCad">Data Cad:</label>
                                        <input  className="form-control datepicker-input" id="dataCad" type="text" placeholder="dd/mm/yyyy" defaultValue={eventoReg.dataCad} onChange={onInputChange} />
            
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        <Toast text={notification.text} type={notification.type} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventoModal;