import React, { useRef, useState, useEffect } from "react";
import { saveContato } from "../../../services/ContatosService";
import { getEstadosAll } from "../../../services/EstadosService";
import DateFilter from "../../../components/DateFilter/DateFilter";
import Toast from "../../../components/Toast/Toast";
import SelectUF from "../../../components/SelectUF/SelectUF";
import { format } from "date-fns";
import { insertPresenca, getPresenca } from "../../../services/PresencaService";
import { getContatos, getContato } from "../../../services/ContatosService";
//import PresencaModal from '../Presenca/PresencaModal';
import { getEventos } from "../../../services/EventoService";
import { savePresenca } from "../../../services/PresencaService";
import axios from "axios";

/**
 * props:
 * - data
 * - estados
 * - onSubmit
 */
function ContatoModal(props) {
  const DEFAULT_CONFIRMAR = {
    id: 0,
    contato: "",
    evento: "",
    acompanhantes: 0,
    aceite: "S",
  };

  const DEFAULT_CONTATO = {
    id: 0,
    nome: "",
    email: "",
    celular: "",
    uf: "",
    cargo: "",
    cidade: "",
    pais: "",
    barco_local: "",
    barco_marca: "",
    barco_modelo: "",
    barco_possui: "",
    barco_tamanho: "",
    empresa_cliente: "",
    telefone: "",
    data_criacao: "",
    hora_criacao: 0,
    contato_envio_comunicacao: "",
    status_contato: "",
    endereco: "",
    email_2: "",
    cep: "",
    visitou_evento: "",
    data_Evento: "",
    sexo: "",
    qtde_acompanhantes: 0,
    termo_aceite: "",
  };

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [contatoReg, setContatoReg] = useState(DEFAULT_CONTATO);
  const [notification, setNotification] = useState({});

  const [estados, setEstados] = useState([]);
  const [count, setCount] = useState(0);

  const btnClose = useRef("");
  const btnSave = useRef("");
  const btnConfirma = useRef("");
  const [evento, setEventos] = useState([]);
  const [proximo, setProximo] = useState([]);
  const [confirmar, setConfirmar] = useState(DEFAULT_CONFIRMAR);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEventos(proximo || 1, token)
      .then((result) => {
        setEventos(result);
        //setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEstadosAll(token)
      .then((result) => {
        setEstados(result.rows);
        setCount(result.count);
        // console.log('result.rows ', result.rows , token);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
    // console.log('Estados ', estados);
  }, []);

  function onUFChange(event) {
    setEstados(event.target.value);
  }

  function onPresenca(event) {}

  function onSubmit(event) {
    if (!contatoReg.nome || !contatoReg.email || !contatoReg.celular)
      return setNotification({
        type: "error",
        text: `Verificar campos obrigatórios!!!`,
      });

    const token = localStorage.getItem("token");

    saveContato(contatoReg.id, contatoReg, token)
      .then((result) => {
        confirmarPresenca(result.id, contatoReg.qtde_acompanhantes);

        console.log("Salva Contato ", result.id, contatoReg);
        if (props.onSubmit) props.onSubmit(result);
        setNotification({
          type: "success",
          text: `Contato Incluido com sucesso!!`,
        });
        btnClose.current.click();
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.message);
      });
  }

  function onInputChange(event) {
    setContatoReg((prevState) => ({
      ...prevState,
      [event.target.id]: event.target.value,
    }));
  }

  useEffect(() => {
    setContatoReg(props.data);
    // console.log("setContatoModal ", props.data);
  }, [props.data, props.data.id]);

  useEffect(() => {
    const modal = document.getElementById("modalContato");
    modal.addEventListener("hidden.bs.modal", (event) => {
      setContatoReg({ ...DEFAULT_CONTATO });
    });
  }, []);

  // useEffect(() => {
  //     btnClose.current.click();
  //     const modal = document.getElementById('modalPresenca');
  //     modal.addEventListener('hidden.bs.modal', (event) => {
  //         //setPResencaModal({ ...DEFAULT_PRESENCA });
  //     })
  // }, [])

  function confirmarPresenca(contatoId, acompanhantes) {
    const token = localStorage.getItem("token");
    confirmar.id = 0;
    confirmar.contato = contatoId;
    confirmar.evento = evento.id;
    confirmar.acompanhantes = acompanhantes;
    console.log("Salva presença ", confirmar);
    savePresenca(0, confirmar, token)
      .then((result) => {
        //  btnClose.current.click();
        console.log("Salva presença ", confirmar);
        //   if (props.onSubmit) props.onSubmit(result);
        return setNotification({
          type: "success",
          text: `Presença Confirmada com sucesso!!`,
        });
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setError(err.message);
      });
  }

  return (
    <div
      className="modal fade"
      id="modalContato"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="modalTitleSubscribe"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content  py-xl-4">
          <div className="modal-header">
            <p className="modal-title" id="modalTitleSubscribe">
              {props.data.id ? "Editar " : "Novo "}Contato
            </p>
            <button
              ref={btnClose}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="id">ID</label>
                    <input
                      className="form-control"
                      id="id"
                      type="number"
                      placeholder="ID"
                      value={contatoReg.id}
                      onChange={onInputChange}
                      disabled
                    />
                  </div>
                </div>

                <div className="col-md-9 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="email">e-mail:</label>
                    <input
                      className="form-control datepicker-input"
                      id="email"
                      type="email"
                      placeholder="seu.email@dominio.com"
                      value={contatoReg.email || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="nome">Nome</label>
                    <input
                      className="form-control"
                      id="nome"
                      type="text"
                      placeholder="Name"
                      value={contatoReg.nome || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="qtde_acompanhantes">
                      Qtd Acompanhantes :
                    </label>
                    <input
                      className="form-control datepicker-input"
                      id="qtde_acompanhantes"
                      type="number"
                      placeholder="0"
                      value={contatoReg.qtde_acompanhantes || 0}
                      onChange={onInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="sexo">Sexo</label>
                    <select
                      className="form-select"
                      id="sexo"
                      value={contatoReg.sexo || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option selected>Não Informado</option>
                      <option value="Feminino">Feminino</option>
                      <option value="Masculino">Masculino</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="celular">Celular:</label>
                    <input
                      className="form-control datepicker-input"
                      id="celular"
                      type="text"
                      placeholder="(00) 0000-0000"
                      value={contatoReg.celular || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="telefone">Telefone:</label>
                    <input
                      className="form-control datepicker-input"
                      id="telefone"
                      type="text"
                      placeholder="(00) 0000-0000"
                      value={contatoReg.telefone || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="endereco">Endereço:</label>
                    <input
                      className="form-control datepicker-input"
                      id="endereco"
                      type="text"
                      placeholder="Endereço"
                      value={contatoReg.endereco || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="uf">UF:</label>
                    {contatoReg.uf}
                    <select
                      className="form-select"
                      id="uf"
                      value={contatoReg.uf || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option value="">Selecione um Estado</option>
                      <option value="AC">ACRE</option>
                      <option value="AL">ALAGOAS</option>
                      <option value="AM">AMAZONAS</option>
                      <option value="AP">AMAPÁ</option>
                      <option value="BA">BAHIA</option>
                      <option value="CE">CEARA</option>
                      <option value="DF">BRASILIA</option>
                      <option value="ES">ESPIRITO SANTO</option>
                      <option value="GO">GOIAS</option>
                      <option value="MA">MARANHAO</option>
                      <option value="MG">MINAS GERAIS</option>
                      <option value="MS">MATO GROSSO DO SUL</option>
                      <option value="MT">MATO GROSSO</option>
                      <option value="PA">PARA</option>
                      <option value="PB">PARAIBA</option>
                      <option value="PE">PERNAMBUCO</option>
                      <option value="PI">PIAUI</option>
                      <option value="PR">PARANA</option>
                      <option value="RJ">RIO DE JANEIRO</option>
                      <option value="RN">RIO GRANDE DO NORTE </option>
                      <option value="RO">RONDONIA</option>
                      <option value="RR">RORAIMA</option>
                      <option value="RS">RIO GRANDE DO SUL</option>
                      <option value="SC">SANTA CATARINA</option>
                      <option value="SE">SERGIPE</option>
                      <option value="SP">SÃO PAULO</option>
                      <option value="TO">TOCANTINS</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="pais">País:</label>
                    <select
                      className="form-select"
                      id="pais"
                      value={contatoReg.pais || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option selected>País</option>
                      <option value="AFEGANISTAO">AFEGANISTAO</option>
                      <option value="AMERICAN SAMOA">AMERICAN SAMOA</option>
                      <option value="ANTIGUA E BARBUDA">
                        ANTIGUA E BARBUDA
                      </option>
                      <option value="ARGENTINA">ARGENTINA</option>
                      <option value="BRASIL">BRASIL</option>
                      <option value="CHILE">CHILE</option>
                      <option value="COLOMBIA">COLOMBIA</option>
                      <option value="EMIRADOS ARABES">EMIRADOS ARABES</option>
                      <option value="ITALIA">ITALIA</option>
                      <option value="PARAGUAI">PARAGUAI</option>
                      <option value="PORTUGAL">PORTUGAL</option>
                      <option value="URUGUAI">URUGUAI</option>
                      <option value="EXTERIOR">EXTERIOR</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="cep">Cep:</label>
                    <input
                      className="form-control datepicker-input"
                      id="cep"
                      type="text"
                      placeholder="CEP"
                      value={contatoReg.cep}
                      onChange={onInputChange}
                      onBlur={async () => {
                        let response = await axios.get(
                          `https://viacep.com.br/ws/${contatoReg.cep}/json/`
                        );
                        setContatoReg({
                          ...contatoReg,
                          cidade: response.data.localidade,
                          endereco: `${response.data.logradouro} ${response.data.complemento} - ${response.data.bairro}`,
                          uf: response.data.uf,
                          cep: response.data.cep,
                        });

                        // console.log("nao to mais no foco ", response.data);
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="cidade">Cidade:</label>
                    <input
                      className="form-control datepicker-input"
                      id="cidade"
                      type="text"
                      placeholder="Cidade"
                      value={contatoReg.cidade}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="barco_possui">Possuí Barco:</label>
                    <select
                      className="form-select"
                      id="barco_possui"
                      value={contatoReg.barco_possui || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option selected>Não</option>
                      <option value="S">Sim</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="barco_tamanho">Tamanho Barco:</label>
                    <select
                      className="form-select"
                      id="barco_tamanho"
                      value={contatoReg.barco_tamanho || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option selected>Nenhum</option>
                      <option value="Até 49 pés">Até 49 pés</option>
                      <option value="De 50 até 60 pés">De 50 até 60 pés</option>
                      <option value="De 61 até 70 pés">De 61 até 70 pés</option>
                      <option value="Acima de 71 pés">Acima de 71 pés</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="barco_marca">Marca:</label>
                    <select
                      className="form-select"
                      id="barco_marca"
                      value={contatoReg.barco_marca || ""}
                      onChange={onInputChange}
                      aria-label="Default select example"
                    >
                      <option selected value="Nenhum">
                        Nenhum
                      </option>
                      <option value="Azimut">Azimut</option>
                      <option value="Benetti">Benetti</option>
                      <option value="Benetton">Benetton</option>
                      <option value="Ferretti">Ferretti</option>
                      <option value="Fibrafort">Fibrafort</option>
                      <option value="Intermarine">Intermarine</option>
                      <option value="Mangusta">Mangusta</option>
                      <option value="Okean">Okean</option>
                      <option value="Princess">Princess</option>
                      <option value="Sanlorenzo">Sanlorenzo</option>
                      <option value="Schaefer">Schaefer</option>
                      <option value="Sunseeker">Sunseeker</option>
                      <option value="Outra">Outra</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-3 mb-3">
                  <div className="form-group mb-2">
                    <label htmlFor="barco_modelo">Modelo:</label>
                    <input
                      className="form-control datepicker-input"
                      id="barco_modelo"
                      type="text"
                      placeholder="Modelo Barco"
                      value={contatoReg.barco_modelo || ""}
                      onChange={onInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {error ? (
              <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {/* <PresencaModal data={contatoReg} contato={contatoReg.id } evento={6}  />  */}
            <Toast text={notification.text} type={notification.type} />
            {/* <button ref={btnConfirma} type="button" className="btn btn-sm btn-primary" onClick={onPresenca}>Confirmar</button> */}
            <button
              ref={btnSave}
              type="button"
              className="btn btn-sm btn-primary"
              onClick={onSubmit}
            >
              Save
            </button>
            {contatoReg.id > 0 ? (
              <button
                id={contatoReg.id}
                type="button"
                className="btn btn-secondary btn-xs ms-2"
                title="Confirmar Presença"
                data-bs-toggle="modal"
                data-bs-target="#modalConfirmar"
                onClick={props.onEditClick}
              >
                <svg
                  id={contatoReg.id}
                  className="icon icon-xs"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  onClick={props.onEditClick}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  ></path>
                </svg>
              </button>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContatoModal;
