import React, { useRef, useState, useEffect } from "react";
import Toast from '../../../components/Toast/Toast';
import { getEventos } from "./../../../services/EventoService";
import { savePresenca } from '../../../services/PresencaService';
/**
 * props:
 * - data
 * - estados
 * - onSubmit
 */
function ModalConfirma(props) {

    const DEFAULT_CONFIRMAR = {
        id: 0,
        contato: '',
        evento: '',
        acompanhantes: 0,
        aceite: 'S'
    }

    const btnClose = useRef('');
    const btnSave = useRef('');
    const [evento, setEventos] = useState([]); 
    const [confirmar, setConfirmar] = useState(DEFAULT_CONFIRMAR);
    const [contato, setContato] = useState([]);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [notification, setNotification] = useState({});
    const [error, setError] = useState('');  
    const [success, setSuccess] = useState('');
    const [proximo, setProximo] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        getEventos(  proximo || 1,token)
            .then(result => {
                setEventos(result);
                //setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [])

    function onSubmit(event) {
        const token = localStorage.getItem('token');
        confirmar.id = 0;
        confirmar.contato = contato.id;
        confirmar.evento = evento.id;

        savePresenca(0, confirmar, token)
            .then(result => {
                btnClose.current.click();
                console.log('Salva Contato ', confirmar);
                if (props.onSubmit) props.onSubmit(result);
                return setNotification({ type: 'success', text: `Presença Confirmada com sucesso!!`});
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.message);
            })
    }

    function onEventosChange(event) {
        setEventos(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    function onInputChange(event) {
        setConfirmar(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        setContato(props.data);
        // console.log('setContatoModal ', props.data);
    }, [props.data.id])

    
    useEffect(() => {
        const modal = document.getElementById('modalConfirma');
        modal.addEventListener('hidden.bs.modal', (event) => {
            // setConfirmar({...DEFAULT_CONFIRMAR});
        })
    }, [])


    return (
        <div className="modal fade" id="modalConfirma" tabIndex="-1" role="dialog" aria-labelledby="modalTitleSubscribe" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content  py-xl-4">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleSubscribe">Confimar Presença</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="id">ID</label>
                                        <input className="form-control" id="id" type="number" placeholder="ID" value={contato.id}  disabled />
                                    </div>
                                </div>  

                                <div className="col-md-9 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="nome">Nome:</label>
                                        <input  className="form-control datepicker-input" id="nome" type="text" value={contato.nome || ""} disabled /> 
                                    </div>
                                </div> 
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="evento">Evento:</label>
                                        <select className="form-select" id="evento" value={evento.id || ""} onChange={onEventosChange} aria-label="Default select example">
                                            <option value={evento.id}>{evento.evento} - {evento.datainicio}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="acompanhantes">Qtde Acompanhantes :</label>
                                        <input className="form-control datepicker-input" id="acompanhantes" type="number"  value={confirmar.acompanhantes || 0} onChange={onInputChange} />                                 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               
                <div className="modal-footer">
                {
                    error
                        ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                        : <React.Fragment></React.Fragment>
                }
                <Toast text={notification.text} type={notification.type} />
                <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                   
                </div>
            </div>
            </div>
        </div>
    )
}

export default ModalConfirma;