import React, { useState, useEffect } from 'react';
import { getEstados } from '../../services/EstadosService';

/**
 * props:
 * - coin
 * - onChange
 */
function SelectUF(props) {

    const [estados, setEstados] = useState([]);

    useEffect(() => {
        getEstados()  
            .then(estados => {
                if (!estados.rows.map) return setEstados([]);
               // const coinNames = [...new Set(estados.rows.map(s => s.base))].sort();
              ///  setEstados(coinNames);
            })
            .catch(err => setEstados([err.response ? err.response.data : err.message]));
    }, [])

    function onUFChange(event) {
        if (props.onChange)
            props.onChange(event);
    }

    return (
        <select className="form-select" id="uf" value={props.uf} onChange={onUFChange}>
            <option value="">Select...</option>
            {
                estados
                    ? estados.map(c => (<option key={c}>{c}</option>))
                    : <React.Fragment></React.Fragment>
            }
        </select>
    )
}

export default SelectUF;