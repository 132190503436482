import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Pagination from '../../components/Pagination/Pagination';
import Toast from '../../components/Toast/Toast';
import NewEstadoButton from './NewEstadoButton';
import { getEstados,  deleteEstado } from '../../services/EstadosService';
import EstadoRow from'./EstadoRow';
import EstadosModal from './EstadosModal/EstadosModal';


function Estados() {
/*
    const history = useHistory();
    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    const [estados, setEstados] = useState([]);

    const [count, setCount] = useState(0);

    const [notification, setNotification] = useState({ type: '', text: '' });

    const DEFAULT_ESTADO = {
        uf: '',
        nome: '',
        regiao: ''
    }

    const [editEstado, setEditEstado] = useState(DEFAULT_ESTADO);

    const [page, setPage] = useState(getPage());

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log('token ',token );
        getEstados( token)
            .then(result => {
                setEstados(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page])
  
    return (
        <React.Fragment>
        <Menu />
        <main className="content">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <h1 className="h4">Estados</h1>
                </div>            
            </div>            
            <Footer />
        </main>
        </React.Fragment>
  );
    */

    const defaultLocation = useLocation();

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    const [estados, setEstados] = useState([]);

    const [count, setCount] = useState(0);

    const [notification, setNotification] = useState({ type: '', text: '' });

    const DEFAULT_ESTADO = {
        uf: '',
        nome: '',
        regiao: ''
    }

    const [editEstado, setEditEstado] = useState(DEFAULT_ESTADO);

    const [page, setPage] = useState(getPage());

    useEffect(() => {
        const token = localStorage.getItem("token");
        getEstados(page || 1, token)
            .then(result => {
                setEstados(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        const estado = estados.find(m => m.uf === id);
        setEditEstado({ ...estado });
    }

    function onDeleteClick(event) {
        const id = event.target.uf.replace('delete', '');
        const token = localStorage.getItem('token');
        deleteEstado(id, token)
            .then(estado => { history.go(0) })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message)
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });
    }


    function onEstadoSubmit(automation) {
        history.go(0);
    }

    function onNewEstadoClick(event) {
        setEditEstado(DEFAULT_ESTADO);
    }


    return (
            <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Estados</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewEstadoButton onClick={onNewEstadoClick} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Ação</th>
                                <th className="border-gray-200">UF</th>
                                <th className="border-gray-200">Estado</th>
                                <th className="border-gray-200">Região</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                estados && estados.length
                                    ? estados.map(estado => (<EstadoRow key={estado.uf} data={estado} onEditClick={onEditClick}  onDeleteClick={onDeleteClick} />))
                                    : <React.Fragment></React.Fragment>
                            }
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            <EstadosModal data={editEstado} onSubmit={onEstadoSubmit} /> 
              
            <Toast type={notification.type} text={notification.text} />
              
        </React.Fragment>
    );
}

export default Estados;