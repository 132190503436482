import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import Toast from '../../components/Toast/Toast';
import NewSettingsButton from './NewSettingsButton';
import SearchSetting from './SearchSetting';
import SettingRow from './SettingRow';
import Pagination from '../../components/Pagination/Pagination';
import { getSettings, updateSettings, deleteSettings, startSettings, stopSettings } from '../../services/SettingsService';

function Settings() {

    // const confirmPassword = useRef('');
    // const [settings, setSettings] = useState({});
    // const [notification, setNotification] = useState({});

    // useEffect(() => {

    //     // getSettings()
    //     //     .then(result => setSettings(result))
    //     //     .catch(err => {
    //     //         console.error(err.response ? err.response.data : err.message);
    //     //         setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //     //     })
    // }, []);

    // function onInputChange(event) {
    //     setSettings(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    // }

    // function onFormSubmit(event) {
    //     if ((settings.password || confirmPassword.current.value)
    //         && settings.password !== confirmPassword.current.value)
    //         return setNotification({ type: 'error', text: `The fields New Password and Confirm Password must be equal.` });

    //     updateSettings(settings)
    //         .then(result => {
    //             if (result)
    //                 setNotification({ type: 'success', text: `Settings saved successfully!` });
    //             else
    //                 setNotification({ type: 'error', text: result });
    //         })
    //         .catch(err => {
    //             console.error(err.response ? err.response.data : err.message);
    //             setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //         })
    // }

    const defaultLocation = useLocation();

    const history = useHistory();

    const [users, setUsers] = useState([]);

    const [editUser, setEditUser] = useState({});

    const [count, setCount] = useState(0);

    const [search, setSearch] = useState('');

    const [page, setPage] = useState(getPage());

    const [notification, setNotification] = useState({ type: '', text: '' });

    function getPage(location) {
        if (!location) location = defaultLocation;
        return new URLSearchParams(location.search).get('page');
    }

    useEffect(() => {
        return history.listen((location) => {
            setPage(getPage(location));
        })
    }, [history])

    useEffect(() => {
        getSettings(search, page || 1)
            .then(result => {
                setUsers(result.rows);
                setCount(result.count);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
            });

    }, [page, search])

    function onEditClick(event) {
        const id = event.target.id.replace('edit', '');
        setEditUser(users.find(u => u.id == id));
    }

    // function onStopClick(event) {
    //     const id = event.target.id.replace('stop', '');
    //     stopSettings(id)
    //         .then(result => { history.go(0) })
    //         .catch(err => {
    //             console.error(err.response ? err.response.data : err.message);
    //             setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //         });
    // }

    // function onStartClick(event) {
    //     const id = event.target.id.replace('start', '');
    //     startSettings(id)
    //         .then(result => { history.go(0) })
    //         .catch(err => {
    //             console.error(err.response ? err.response.data : err.message)
    //             setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //         });
    // }

    // function onDeleteClick(event) {
    //     const id = event.target.id.replace('delete', '');
    //     deleteSettings(id)
    //         .then(result => { history.go(0) })
    //         .catch(err => {
    //             console.error(err.response ? err.response.data : err.message);
    //             setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //         });
    // }

    function onSearchChange(event) {
        setSearch(event.target.value);
    }

    function onModalSubmit(event) {
        history.go(0);
    }

    return (
        <React.Fragment>
            <Menu />
            <main className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                    <div className="d-block mb-4 mb-md-0">
                        <h2 className="h4">Usuários</h2>
                    </div>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="d-inline-flex align-items-center">
                            <NewSettingsButton />
                            <SearchSetting placeholder={search} onChange={onSearchChange} />
                        </div>
                    </div>
                </div>
                <div className="card card-body border-0 shadow table-wrapper table-responsive">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200">Nome</th>
                                <th className="border-gray-200">Email</th>
                                <th className="border-gray-200">CPF</th>
                                <th className="border-gray-200">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {
                                users && users.length
                                    ? users.map(user => (<SettingRow key={user.id} data={user} onEditClick={onEditClick} onStartClick={onStartClick} onStopClick={onStopClick} onDeleteClick={onDeleteClick} />))
                                    : <React.Fragment></React.Fragment>
                            } */}
                        </tbody>
                    </table>
                    <Pagination count={count} />
                </div>
                <Footer />
            </main>
            {/* <UserModal data={editUser} onSubmit={onModalSubmit} /> */}
            <Toast type={notification.type} text={notification.text} />
        </React.Fragment>
    )
}

export default Settings;
