import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import ContatoRow from "./ContatoRow";
import {
  getContatos,
  deleteContato,
  getContato,
} from "../../services/ContatosService";
import Pagination from "../../components/Pagination/Pagination";
import ContatoModal from "./ContatoModal/ContatoModal";
//import PresencaModal from './Presenca/PresencaModal';
import Toast from "../../components/Toast/Toast";
import NewContatoButton from "./NewContatoButton";
import SearchContato from "../../components/SearchContato/SearchContato";
import { getEstadosAll } from "../../services/EstadosService";
import QrCodeModal from "./QrCodeScanner/QrCodeModal";
import ModalConfirma from "./Presenca/ModalConfirma";

//import QrCode from "../../components/QRCode/QRCode" ;
// import { QrReader } from 'react-qr-reader';
import QRCodeScanner from "../../components/QRCode/QRCodeScanner";
//import "../../../public/css/style.css";

function Contatos() {
  const defaultLocation = useLocation();
  const [email, setEmail] = useState("");
  const [data, setData] = useState("No result");

  function getPage(location) {
    if (!location) location = defaultLocation;
    return new URLSearchParams(location.search).get("page") || "1";
  }

  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [contatos, setContatos] = useState([]);

  const [editContato, setEditContato] = useState([]);
  const [confContato, setConfContato] = useState([]);

  const [qrdcodetext, setQrcodText] = useState([]);

  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);

  const [page, setPage] = useState(getPage());

  const [notification, setNotification] = useState({});

  const [search, setSearch] = useState(contatos ? contatos : "");

  const [estados2, setEstados2] = useState([]);

  // const qrRef = useRef(null);

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getContatos(search, page || 1, email, token)
      .then((result) => {
        setContatos(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }, [search, page, email]);

  function onSearchChange(event) {
    //  console.log('Estou aqui ', event.target.value);
    setSearch(event.target.value);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEstadosAll(token)
      .then((result) => {
        setEstados2(result.rows);
        setCount2(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }, []);

  function onEditClick(event, data) {
    // console.log("contato item ", data)
    // console.log("Contato event ", event.target);
    // const id = event.target.id.replace("edit", "");
    // console.log("Contato id ", event.target.id, id);
    // const contato = contatos.find((m) => m.id == id);
    // console.log("Contato ", contato, id, contatos);
    setEditContato({ ...data });
    // const contatoC = contatos.find(m => m.id == id);
    // console.log('Contato ', contatoC, id, contatos);
    // setConfContato({ ...contatoC });
    // console.log("EditContato conf ", id, contato, editContato, confContato);
  }

  function onConfirmaClick(event) {
    console.log("Contato event ", event.target);
    const id = event.target.id.replace("edit", "");
    console.log("Contato id ", event.target.id, id);
    const contatoC = contatos.find((m) => m.id == id);
    console.log("Contato ", contatoC, id, contatos);
    setConfContato({ ...contatoC });
    console.log("ConfContato ", contatoC, id, confContato);
  }

  function onDeleteClick(event) {
    const id = event.target.id.replace("delete", "");
    const token = localStorage.getItem("token");
    deleteContato(id, token)
      .then((contato) => {
        history.go(0);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }

  function onContatoSubmit(order) {
    history.go(0);
  }

  function onConfirmaSubmit(order) {
    history.go(0);
  }

  function onNewContatoClick(event) {
    setEditContato("");
  }

  function onQrCodeClick(event) {
    setQrcodText("");
    history.push("/conferencia");
  }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Contatos</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnQrCodeScanner"
                className="btn animate-up-2"
                style={{ backgroundColor: "#1F2937", color: "white" }}
                onClick={onQrCodeClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-bs-toggle="modal"
                  data-bs-target="#modalQRScanner"
                  onClick={onQrCodeClick}
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a2 2 0 012-2h10a2 2 0 012 2v8a2 2 0 01-2 2h-2.22l.123.489.804.804A1 1 0 0113 18H7a1 1 0 01-.707-1.707l.804-.804L7.22 15H5a2 2 0 01-2-2V5zm5.771 7H5V5h10v7H8.771z"
                    clipRule="evenodd"
                  />
                </svg>
                Scan Qr Code
              </button>
            </div>
            <div className="d-inline-flex align-items-center ms-2">
              <NewContatoButton onClick={onNewContatoClick} />
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <label htmlFor="exampleInputIconRight">Pesquisar</label>
          <div className="btn-group ms-2 ms-lg-3">
            <SearchContato onChange={onSearchChange} placeholder={search} />
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Editar/Email/PDF</th>
                {/* <th className="border-gray-200">Presença</th>
                                <th className="border-gray-200">E-mail</th>
                                <th className="border-gray-200">PDF</th> */}
                <th className="border-gray-200">ID</th>
                <th className="border-gray-200">Nome</th>
                {/* <th className="border-gray-200">e-mail</th>
                                <th className="border-gray-200">Celular</th> */}
                <th className="border-gray-200">Cidade</th>
                <th className="border-gray-200">Estado</th>
                {/* <th className="border-gray-200">País</th>
                                <th className="border-gray-200">Possuí Barco</th>
                                <th className="border-gray-200">Modelo Barco</th>
                                <th className="border-gray-200">Tamanho Barco</th>
                                <th className="border-gray-200">Marca Barco</th>
                                <th className="border-gray-200">Local Barco</th> */}
              </tr>
            </thead>
            <tbody>
              {contatos && contatos.length ? (
                contatos.map((contatoItem) => (
                  <ContatoRow
                    key={contatoItem.id}
                    data={contatoItem}
                    onEditClick={onEditClick}
                    onDeleteClick={onDeleteClick}
                  />
                ))
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
        {/* <div>
                    <QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                            setData(result?.text);
                            }

                            if (!!error) {
                            console.info(error);
                            }
                        }}
                        style={{ width: '100%' }}
                        />
                        <p>{data}</p>
                </div> */}
        {/* < div className = "section container" >
                    < QRCodeScanner />
                </ div > */}
        <Footer />
      </main>
      <ContatoModal
        data={editContato}
        estados={estados2}
        onSubmit={onContatoSubmit}
      />
      {/* <PresencaModal data={editContato} contato={editContato.id} evento={6}  />      */}
      <QrCodeModal data={editContato} contato={editContato.id} />
      <ModalConfirma
        data={editContato}
        onSubmit={onConfirmaSubmit}
      ></ModalConfirma>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default Contatos;
