import React, { useState, useEffect } from 'react';

/**
 * props:
 * - data 
 */
function PresencaReport(props) {

    const [presencas, setPresencas] = useState([]);

    useEffect(() => {
        if (!props.data) return;
        setPresencas(props.data);
    }, [props.data])

    return (
        <div className="col-md-12col-sm-12 mb-4">
            <div className="card border-0 shadow"> 
                <div className="card-header">
                    <div className="row">
                        <div className="col">
                            <h2 className="fs-5 fw-bold mb-0">Confirmações de Presença</h2>
                        </div>
                    </div>
                </div>
                <div className="table-responsive divScroll">
                    <table className="table align-items-center table-flush table-sm table-hover tableFixHead">
                        <thead className="thead-light">
                            <tr>
                                <th className="border-bottom col-4" scope="col">Nome</th>
                                <th className="border-bottom col-4" scope="col">E-mail</th>
                                <th className="border-bottom col-2" scope="col">Acompanhantes</th>
                                <th className="border-bottom col-2" scope="col">Data</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(presencas) && presencas.map(item => (
                                (
                                    <tr key={item.id}>
                                        <td className="text-gray-900">
                                            {item.nome}
                                        </td>
                                        <td className="text-gray-900">
                                            {item.email}
                                        </td>
                                        <td className="text-gray-900">
                                            {item.acompanhantes}
                                        </td>
                                        <td className="text-gray-900">
                                            {item.createdAt}
                                        </td>
                                    </tr>
                                )
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default PresencaReport;
