import React, {useState, useRef} from 'react';
import "./styles.css";
import {QrReader} from 'react-qr-reader';


function QrCodeModal() { 


  const btnClose = useRef('');
  const btnSave = useRef('');
  const [selected, setSelected] = useState("environment");
  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");

  const handleScan = async (scanData) => {  
    setLoadingScan(true);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "") {
      console.log(`loaded >>>`, scanData);
      setData(scanData);
      setStartScan(false);
      setLoadingScan(false);
      // setPrecScan(scanData);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div className="modal fade" id="modalQRScanner" tabIndex="-1" role="dialog" aria-labelledby="modalTitleSubscribe" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content  py-xl-4">
            <div className="modal-header">
                <p className="modal-title" id="modalTitleSubscribe">Novo</p>
                <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
            </div>
            <div className="modal-body">
                            
                <div className="App">
                <h1>Hello CodeSandbox</h1>
                <h2>
                    Last Scan:
                    {selected}
                </h2>

                <button
                    onClick={() => {
                    setStartScan(!startScan);
                    }}>
                    {startScan ? "Stop Scan" : "Start Scan"}
                </button>
                {startScan && (
                    <>
                    <select onChange={(e) => setSelected(e.target.value)}>
                        <option value={"environment"}>Back Camera</option>
                        <option value={"user"}>Front Camera</option>
                    </select>
                    <QrReader
                        facingMode={selected}
                        delay={1000}
                        onError={handleError}
                        onScan={handleScan}
                        // chooseDeviceId={()=>selected}
                        style={{ width: '100%' }}
                        onResult={(result, error) => {
                            if (!!result) {
                              setData(result?.text);
                              
                            }
                  
                            if (!!error) {
                              console.info(error);
                            }
                          }}
                    />
                    </>
                )}
                {loadingScan && <p>Loading</p>}
                {data !== "" && <p>{data}</p>}
                </div>
                     
                    </div>
                <div className="modal-footer">
                    {/* {
                        error
                            ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                            : <React.Fragment></React.Fragment>
                    }
                    <PresencaModal data={contatoReg} contato={contatoReg.id } evento={6}  /> 
                    <Toast text={notification.text} type={notification.type} />
                    {/* <button ref={btnConfirma} type="button" className="btn btn-sm btn-primary" onClick={onPresenca}>Confirmar</button> */}
                    {/* <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    {
                    contatoReg.id > 0
                    ?   <button id={contatoReg.id} type="button" className="btn btn-secondary btn-xs ms-2" title="Confirmar Presença" data-bs-toggle="modal" data-bs-target="#modalPresenca" onClick={props.onEditClick}>   
                            <svg id={contatoReg.id} className="icon icon-xs" fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"  onClick={props.onEditClick}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"></path>
                            </svg>
                        </button>
                    : <React.Fragment></React.Fragment>
                        } */} 
                </div>
                   
                </div>
            </div>
        </div>
    )
}

// const useStyles = makeStyles((theme) => ({
//     conatiner: {
//       marginTop: 10
//     },
//     title: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems:  'center',
//       background: '#3f51b5',
//       color: '#fff',
//       padding: 20
//     },
//     btn : {
//       marginTop: 10,
//       marginBottom: 20
//     }
// }));
export default QrCodeModal;