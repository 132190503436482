import React from 'react';
import { Route, BrowserRouter, Redirect } from 'react-router-dom';
import Login from './public/Login/Login';
import Dashboard from "./private/Dashboard/Dashboard";
import Eventos from "./private/Eventos/Eventos";
import Estados from './private/Estados/Estados';
import Contatos from './private/Contatos/Contatos';
import Conferencia from './private/Conferencia/Conferencia';
import Settings from './private/Settings/Settings';
import Users from './private/User/Users';
import Reports from './private/Reports/Reports';
import WhatsApp from '@mui/icons-material/WhatsApp';
import WhatsAppSettings from './private/WhatsAppSettings';


function Routes() {

    function PrivateRoute({ children, ...rest }) {
        return (
            <Route {...rest} render={() => {
                return localStorage.getItem("token")
                    ? children
                    : <Redirect to='/' />
            }} />
        )
    }

    return (
        <BrowserRouter>
            <Route path="/" exact>
                <Login />
            </Route>
            <PrivateRoute path="/dashboard">
                <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/(freports|reports)/">
                <Reports />
            </PrivateRoute>
            <PrivateRoute path="/eventos">
                <Eventos />
            </PrivateRoute>
            <PrivateRoute path="/estados">  
                <Estados />
            </PrivateRoute>
            <PrivateRoute path="/contatos">
                <Contatos />
            </PrivateRoute>
            <PrivateRoute path="/conferencia">
                <Conferencia />
            </PrivateRoute>
            <PrivateRoute path="/settings">
                <Settings />
            </PrivateRoute>
            <PrivateRoute path="/users">
                <Users />
            </PrivateRoute>
            <PrivateRoute path="/whatsapp">
                <WhatsAppSettings />
            </PrivateRoute>
        </BrowserRouter>
    )
}

export default Routes;
