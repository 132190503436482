import React, { useRef, useState, useEffect } from 'react';
import { saveEstado } from '../../../services/EstadosService';
import DateFilter from '../../../components/DateFilter/DateFilter';
import Toast from '../../../components/Toast/Toast';
import { format } from 'date-fns'

/**
 * props:
 * - data
 * - onSubmit
 */
function EstadosModal(props) {

    const DEFAULT_ESTADO = {
       uf: '',
       nome: '',
       regiao: ''
    }

    const [error, setError] = useState('');

    const [estadoReg, setEstadoReg] = useState(DEFAULT_ESTADO);
    const [notification, setNotification] = useState({});

    const btnClose = useRef('');
    const btnSave = useRef('');

    function onSubmit(event) {
        if ((estadoReg.uf || estadoReg.nome))
            return setNotification({ type: 'error', text: `Verifique os campos obrigatórios.` });

        const token = localStorage.getItem('token');
        console.log('Salva Estado ', estadoReg);
        saveEstado(estadoReg.uf, estadoReg, token)
            .then(result => {
                btnClose.current.click();
                if (props.onSubmit) props.onSubmit(result);
            })
            .catch(err => {
                console.error(err.response ? err.response.data : err.message);
                setError(err.message);
            })
    }

    function onInputChange(event) {
        setEstadoReg(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    }

    useEffect(() => {
        setEstadoReg(props.data);
        console.log('setEstadoReg modal ', props.data);
    }, [props.data.uf])

    
    useEffect(() => {
        const modal = document.getElementById('modalEstado');
        modal.addEventListener('hidden.bs.modal', (event) => {
            setEstadoReg({ ...DEFAULT_ESTADO });
        })
    }, [])


    return (
        <div className="modal fade" id="modalEstado" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.uf ? 'Edit ' : 'New '}Estado</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                    <label htmlFor="uf">UF</label>
                                        <input className="form-control" id="uf" type="text" placeholder="UF" value={estadoReg.uf} onChange={onInputChange} />
                                    </div>
                                </div>   
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                    <label htmlFor="nome">Nome</label>
                                        <input className="form-control" id="nome" type="text" placeholder="Name Estado" value={estadoReg.nome} onChange={onInputChange} />
                                    </div>
                                </div>   
                            </div>
                        </div>

                        <div className="form-group">    
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <div className="form-group mb-2">
                                        <label htmlFor="regiao">Região:</label> 
                                        <select className="form-select" id="regiao" value={estadoReg.regiao} onChange={onInputChange} aria-label="Default select example">
                                            <option selected>Não Informado</option>
                                            <option value="SUL">SUL</option>
                                            <option value="NORTE">NORTE</option>
                                            <option value="NORDESTE">NORDESTE</option>
                                            <option value="CENTRO-OESTE">CENTRO-OESTE</option>
                                            <option value="SUDESTE">SUDESTE</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        {
                            error
                                ? <div className="alert alert-danger mt-1 col-9 py-1">{error}</div>
                                : <React.Fragment></React.Fragment>
                        }
                        <Toast text={notification.text} type={notification.type} />
                        <button ref={btnSave} type="button" className="btn btn-sm btn-primary" onClick={onSubmit}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default EstadosModal;