import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DateFilter from "../../components/DateFilter/DateFilter";
import Menu from "../../components/Menu/Menu";
import Footer from "../../components/Footer/Footer";
import Toast from "../../components/Toast/Toast";
import { Chart } from "react-google-charts";
import QRCode from "qrcode.react";
import { getReportPresenca } from "../../services/PresencaService";
import {
  getContatosPorEvento,
  getContatosPorEventoDashboard,
  getMarcaBarcos,
  getPessoasPorEstado,
  getPessoasPorRegiao,
  getPessoasPorSexo,
  getPessoasPossuiBarco,
  getTamanhoBarcos,
} from "../../services/ContatosService";
import { Col, Form, Row } from "react-bootstrap";
import { getEventos } from "../../services/EventoService";

export const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7],
];

export const datas = [
  ["Element", "teste", { role: "style" }],
  ["Copper", 8.94, "#b87333"], // RGB value
  ["Silver", 10.49, "silver"], // English color name
  ["Gold", 19.3, "gold"],
  ["Platinum", 21.45, "color: #e5e4e2"], // CSS-style declaration
];

export const options = {
  title: "Monthly Coffee Production by Country",
  vAxis: { title: "Cups" },
  hAxis: { title: "Month" },
  seriesType: "bars",
  series: { 5: { type: "line" } },
  is3D: true,
};

export const dataTest = [
  ["", "Pessoas"],
  ["SP", 1000],
  ["DF", 1170],
  ["PA", 660],
  ["SC", 1030],
];

export const optionsTest = {
  chart: {
    title: "Company Performance",
    subtitle: "Sales, Expenses, and Profit: 2014-2017",
  },
};

function Dashboard() {
  const history = useHistory();
  const [filter, setFilter] = useState({});
  const [report, setReport] = useState({});
  const [notification, setNotification] = useState([]);

  // graphics
  const [marcaBarcos, setMarcaBarcos] = useState();
  const [pessoaspossuiBarco, setPessoasPossuiBarco] = useState();
  const [pessoasPorRegiao, setPessoasPorRegiao] = useState();
  const [pessoasPorEstado, setPessoasPorEstado] = useState();
  const [tamanhoBarco, setTamanhoBarco] = useState();
  const [pessoasPorSexo, setPessoasPorSexo] = useState();

  const [eventoSelect, setEventosSelect] = useState([]);
  const [eventoTarget, setTargetEvent] = useState({});

  // const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // let promise;
    // //     if (filter.startDate && filter.endDate)
    // //        promise = getDayTradeReport(filter.symbol, filter.startDate);
    // //    else
    // promise = getReportPresenca(10, "sexo", filter.startDate, filter.endDate);

    // promise
    //   .then((result) => setReport(result))
    //   .catch((err) => {
    //     console.error(err.response ? err.response.data : err.message);
    //     setNotification({
    //       type: "error",
    //       text: err.response ? err.response.data : err.message,
    //     });
    //   });
    // getReportPresenca(10, 'sexo', filter.startDate, filter.endDate)
    //     .then(result => {
    //         setReport(result);
    //         console.log('getReportSexo ', result)
    //     })
    //     .catch(err => {
    //         console.error(err.response ? err.response.data : err.message);
    //         setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    //     });

    getPessoasPorEstado(token)
      .then((result) => {
        console.log("the people from states is ", result);
        setPessoasPorEstado(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getPessoasPorRegiao(token)
      .then((result) => {
        console.log("the people from regions is ", result);
        setPessoasPorRegiao(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getPessoasPossuiBarco(token)
      .then((result) => {
        console.log("the people with boat or not is ", result);
        setPessoasPossuiBarco(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getMarcaBarcos(token)
      .then((result) => {
        console.log("the badges is ", result);
        setMarcaBarcos(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getTamanhoBarcos(token)
      .then((result) => {
        console.log("the badges is ", result);
        setTamanhoBarco(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getPessoasPorSexo(token)
      .then((result) => {
        console.log("people for gender", result);
        setPessoasPorSexo(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, [filter]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEventos("", "", token)
      .then((result) => {
        console.log("get eventos", result);
        setEventosSelect(result);
        //setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });
  }, []);

  async function onDateChange(event) {
    const { value } = event.target;
    const token = localStorage.getItem("token");
    const response = await getContatosPorEventoDashboard(
      token,
      "",
      value.startDate,
      value.endDate
    );
    // console.log("resultado em busca por data ", response);
    setTargetEvent(response);

    // console.log("porra");
  }

  async function changeEventChange(event) {
    const { name, value } = event.target;
    const token = localStorage.getItem("token");

    const response = await getContatosPorEventoDashboard(token, value);
    // console.log("eventos ", event);
    // console.log("eventos ", name, value);
    // console.log("response from the back is ", response);
    setTargetEvent(response);
  }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h1 className="h4">Dashboard</h1>
          </div>
        </div>
        <Row className="mb-5">
          <Col>
            <Form.Group>
              <Form.Select
                aria-label="Default select example"
                className="form-select"
                name="evento"
                // value={evento.id}
                onChange={changeEventChange}
              >
                {eventoSelect.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.evento} - {option.datainicio}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <DateFilter onClick={onDateChange} />
          </Col>
        </Row>
        <div className="form-group">
          {/* <QRCode value={JSON.stringify({id: "123", email: "denis@gmail.com"})} /> */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Estado</h1>
                  {/* <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={datas}
                  /> */}
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.estado : pessoasPorEstado}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Região</h1>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.regiao : pessoasPorRegiao}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Possui Barco</h1>
                  {/* <Chart
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={datas}
                  /> */}
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.possuiBarco : pessoaspossuiBarco}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Marca</h1>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.marca : marcaBarcos}
                    options={{
                      bars: "horizontal",
                      axes: {
                        y: {
                          0: { side: "left" },
                        },
                      },
                    }}
                  />
                  {/* <Chart
                                        chartType="PieChart"
                                        data={data}
                                        options={options}
                                        width={"100%"}
                                        height={"400px"}
                                    />    */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Tamanho</h1>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.tamanho : tamanhoBarco}
                    options={{
                      bars: "horizontal",
                      axes: {
                        y: {
                          0: { side: "left" },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-group mb-2">
                <div className="d-block mb-6 mb-md-0">
                  <h1 className="h5">Sexo</h1>
                  <Chart
                    chartType="Bar"
                    width="100%"
                    height="400px"
                    data={Object.keys(eventoTarget).length > 1 ? eventoTarget.sexo : pessoasPorSexo}
                    // options={options}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default Dashboard;
