import "./styles.css";
import React, { useState, useEffect, useRef,  Component  } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Menu from '../../components/Menu/Menu';
import Footer from '../../components/Footer/Footer';
import {QrReader} from 'react-qr-reader';
import Toast from '../../components/Toast/Toast';
import { getContatos, getContato } from "../../services/ContatosService";
import { getEventos } from "../../services/EventoService";
import { savePresenca } from '../../services/PresencaService';

function Conferencia(){

  const DEFAULT_CONFIRMAR = {
    id: 0,
    contato: '',
    evento: '',
    acompanhantes: 0,
    aceite: 'S'
}

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [email, setEmail] = useState('');
  const [startScan, setStartScan] = useState(true);
  const history = useHistory();
  const [evento, setEventos] = useState([]);
  const [confirmar, setConfirmar] = useState(DEFAULT_CONFIRMAR);
  
  const [data, setData] = useState('No result');
  const [contatos, setContatos] = useState([]);
  const [scanContato, setScanContato] = useState([]);
  const [contatoID, setContatoID] = useState([]);
  const [count, setCount] = useState(0);
  const [notification, setNotification] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [proximo, setProximo] = useState([]);
  const btnClose = useRef('');
  const showModal = useRef('');
  const btnSave = useRef('');
  
  useEffect(() => {
    const token = localStorage.getItem("token"); 
    getEventos(  proximo || 1,token)
        .then(result => {
            setEventos(result);
            //setCount(result.count);
            setStartScan(!startScan);
        })
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
            setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
        });

}, []);

useEffect(() => {
  const token = localStorage.getItem("token");
  
  console.log('Contato get ', search, page, email);
  if(email !== " " && email !== undefined  // && scanContato.id !== undefined && scanContato.id > 0
  ){
  // getContatos( search, page, email, scanContato.id, token)
  getContato(email)
    .then(res => {
      setContatos(res);
      setScanContato({ ...res });
    //  setCount(res.count);
    //  console.log('Contato get ',  res.nome, res.id);  
     // const contatoObj = res.find(m => m.email == email);
      
   //   setContatoID({ ...contatoObj });
      console.log('contato email d ', contatoID, contatoID.id , contatos.id);
      setStartScan(!startScan);
      
    })
    .catch(err => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({ type: 'error', text: err.response ? err.response.data : err.message });
    });
  }
}, [email])

  // function onConfirmaSubmit(order) {
  //   btnClose.current.click();
  // }

  function onEventosChange(event) {
    setEventos(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
  }

  function onInputChange(event) {
    setConfirmar(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
  }



  function onSubmit(event) {
    const token = localStorage.getItem('token');
    confirmar.id = 0;
    confirmar.contato = scanContato.id;
    confirmar.evento = evento.id;

    savePresenca(0, confirmar, token)
        .then(result => {
            // btnClose.current.click();

            console.log('Salva Contato ', confirmar);
            // if (props.onSubmit) props.onSubmit(result);
            history.go(0);
            return setNotification({ type: 'success', text: `Presença Confirmada com sucesso!!`});
            
        })
        .catch(err => {
            console.error(err.response ? err.response.data : err.message);
            setError(err.message);
        })
}

  return (
    <React.Fragment>
    <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
             <div className="d-block mb-4 mb-md-0">
                 <h2 className="h4">Confirmar Presença</h2>
             </div>
        </div> 
        <div className="col-md-6 mb-3">
            
          <button className="btn btn-info animate-up-2"
                    onClick={() => {
                    setStartScan(!startScan);
                    }}>
                    {startScan ? "Stop Scan" : "Start Scan"}
                </button>
                {startScan && (
                    <QrReader
                      onResult={(result, error) => {
                        if (!!result && result !== undefined) {
                          setEmail(result?.text);
                        }

                        if (!!error) {
                          console.info(error);
                        }
                      }}
                      style={{ width: '25%' }}
                      
                      constraints={ {facingMode: 'environment'} }
                    />
                    )}
            <p>{email}</p>
            <div>
          </div>
        </div> 
        {
          scanContato.id >0
            ?  <div className="modal-body">
                  <div className="form-group">
                      <div className="row">
                          <div className="col-md-3 mb-3">
                              <div className="form-group mb-2">
                                  <label htmlFor="id">ID</label>
                                  <input className="form-control" id="id" type="number" placeholder="ID" value={scanContato.id || 0}  disabled />
                              </div>
                          </div>  

                          <div className="col-md-9 mb-3">
                              <div className="form-group mb-2">
                                  <label htmlFor="nome">Nome:</label>
                                  <input  className="form-control datepicker-input" id="nome" type="text" value={scanContato.nome || ""} disabled /> 
                              </div>
                          </div> 
                          <div className="col-md-12 mb-3">
                              <div className="form-group mb-2">
                                  <label htmlFor="evento">Evento:</label>
                                  <select className="form-select" id="evento" tvalue={evento.id || ""} onChange={onEventosChange} aria-label="Default select example">
                                      <option value={evento.id}>{evento.evento} - {evento.datainicio}</option>
                                  </select>
                              </div>
                          </div>
                          <div className="col-md-6 mb-3">
                              <div className="form-group mb-2">
                                  <label htmlFor="acompanhantes">Qtde Acompanhantes :</label>
                                  <input className="form-control datepicker-input" id="acompanhantes" type="number"  value={confirmar.acompanhantes || 0} onChange={onInputChange} />                                 
                              </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button ref={btnSave} type="button" className="btn btn-info animate-up-2" onClick={onSubmit}>Confirmar Presença</button>
                      </div>
                    </div>
            : <React.Fragment></React.Fragment>
        } 
        <Footer />
      </main>

    
    <Toast text={notification.text} type={notification.type} />
    </React.Fragment> 
  );
};

export default Conferencia;
