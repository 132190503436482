import React, { useState, useEffect } from "react";
import Toast from "../../components/Toast/Toast";
import Menu from "../../components/Menu/Menu";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
// import SelectQuote, { getDefaultQuote } from '../../components/SelectQuote/SelectQuote';
import { getReportPresenca } from "../../services/PresencaService";
import DateFilter from "../../components/DateFilter/DateFilter";
import LineChart from "./LineChart";
// import Wallet from '../../components/Wallet/Wallet';
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import PresencaReport from "./PresencaReport";
import { getEventos } from "../../services/EventoService";

import { Row, Col, Form } from "react-bootstrap";
import {
  getAcompanhantes,
  getContatos,
  getContatosPorEvento,
} from "../../services/ContatosService";

function Reports() {
  const history = useHistory();
  const [proximo, setProximo] = useState([]);
  const [filter, setFilter] = useState({});
  const [notification, setNotification] = useState([]);
  const [report, setReport] = useState({});
  const [refresh, setRefresh] = useState(0);
  const [evento, setEventos] = useState([]);
  const [visitantes, setVisitantes] = useState([]);
  const [acompanhantes, setAcompanhantes] = useState([]);
  const [targetEvent, setTargetEvent] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    getEventos("", "", token)
      .then((result) => {
        console.log("get eventos", result);
        setEventos(result);
        //setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
        setNotification({
          type: "error",
          text: err.response ? err.response.data : err.message,
        });
      });

    getContatos("", "", "", token)
      .then((result) => {
        setVisitantes(result.length);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });

    getAcompanhantes(token)
      .then((result) => {
        // console.log("acompanhantes is ", result)
        setAcompanhantes(result);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }, [refresh]);

  // useEffect(() => {
  //   // if (!filter || !filter.symbol) return setFilter({ symbol: getDefaultQuote() });

  //   let promise;
  //   // if (filter.startDate && filter.startDate.getTime() === filter.endDate.getTime())
  //   //     promise = getDayTradeReport(filter.symbol, filter.startDate, showFutures());
  //   // else
  //   //     promise = getOrdersReport(filter.symbol, filter.startDate, filter.endDate, showFutures());

  //   // promise =
  //   //  getReportPresenca(10, 'todos', filter.startDate, filter.endDate)

  //   //  promise
  //   getReportPresenca(evento.id, "todos", filter.startDate, filter.endDate)
  //     .then((result) => setReport(result))
  //     .catch((err) => {
  //       console.error(err.response ? err.response.data : err.message);
  //       setNotification({
  //         type: "error",
  //         text: err.response ? err.response.data : err.message,
  //       });
  //     });
  // }, [evento, filter, refresh]);

  // function onQuoteChange(event) {
  //   setFilter((prevState) => ({ ...prevState, symbol: event.target.value }));
  // }

  async function onDateChange(event) {
    const { value } = event.target;
    const token = localStorage.getItem("token");
    console.log(value.startDate);
    console.log(value.endDate);
    const response = await getContatosPorEvento(
      token,
      "",
      value.startDate,
      value.endDate
    );
    console.log("resultado em busca por data ", response);
    setTargetEvent(response);
    // setFilter((prevState) => ({
    //   ...prevState,
    //   startDate: event.target.value.startDate,
    //   endDate: event.target.value.endDate,
    // }));

    console.log("porra");
  }

  async function changeEventChange(event) {
    const { name, value } = event.target;
    const token = localStorage.getItem("token");

    const response = await getContatosPorEvento(token, value);
    console.log("eventos ", event);
    console.log("eventos ", name, value);
    console.log("response from the back is ", response);
    setTargetEvent(response);
    // setEventos((prevState) => ({
    //   ...prevState,
    //   [event.target.id]: event.target.value,
    // }));
  }

  // function showFutures() {
  //     return window.location.href.indexOf('freports') !== -1;
  // }

  // function toggleMarket() {
  //     if (showFutures())
  //         history.push('/reports/');
  //     else
  //         history.push('/freports/');
  //     setRefresh(Date.now());
  // }

  return (
    <React.Fragment>
      <Menu />
      <main className="content">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Reports</h2>
          </div>

          {/* {
                            localStorage.getItem("hasFutures") === "true"
                                ? (
                                    <div className='me-2 mb-3'>
                                        <button className='btn btn-secondary me-2' onClick={toggleMarket}>
                                            {
                                                showFutures()
                                                    ? "Futures"
                                                    : "Spot"
                                            }
                                        </button>
                                    </div>
                                )
                                : <React.Fragment></React.Fragment>
                        }
                        <div className="me-2 mb-3">
                            <SelectQuote onChange={onQuoteChange} noFavorites={true} value={filter.symbol} />
                        </div> */}
          <Row>
            <Col>
              <Form.Group>
                {/* <select className="form-select" id="evento" defaultvalue={evento.id} onChange={changeEventChange} aria-label="Default select example">
                                                <option value={evento.id}>{evento.evento} - {evento.datainicio}</option>
                                            </select> */}
                <Form.Select
                  aria-label="Default select example"
                  className="form-select"
                  name="evento"
                  value={evento.id}
                  onChange={changeEventChange}
                >
                  {evento.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.evento} - {option.datainicio}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <DateFilter onClick={onDateChange} />
            </Col>
          </Row>
        </div>

        <Row>
          <InfoBlock
            title="Visitantes"
            value={targetEvent ? targetEvent.visitantes : visitantes}
            precisionValue={false}
            background="success"
          >
            {/* <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                        </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
              />
            </svg>
          </InfoBlock>
          <InfoBlock
            title="Acompanhantes"
            value={targetEvent ? targetEvent.acompanhantes : acompanhantes}
            precisionValue={false}
            background="secondary"
          >
            {/* <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" /><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clipRule="evenodd" />
                        </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
              />
            </svg>
          </InfoBlock>
          <InfoBlock
            title="Total"
            value={targetEvent ? targetEvent.total : acompanhantes + visitantes}
            precisionValue={false}
            precision={0}
            background="primary"
          >
            {/* <svg className="icon" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                            <path fillRule="evenodd"
                                d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
                                clipRule="evenodd"></path>
                        </svg> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
              />
            </svg>
          </InfoBlock>
        </Row>
        <Row>
          <PresencaReport data={report} />
          {/* <Wallet isFuture={showFutures()} /> */}
        </Row>
        <Footer />
      </main>
      <Toast type={notification.type} text={notification.text} />
    </React.Fragment>
  );
}

export default Reports;
