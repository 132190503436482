import axios from './BaseService';

const PRESENCA_URL = `${process.env.REACT_APP_API_URL}/presenca/`;

export async function getPresencas(search, page, token) {
    const presencaUrl = `${PRESENCA_URL}?search=${search}&page=${page}`;

    const headers = { 'authorization': token };
    const response = await axios.get(presencaUrl, { headers });
    return response.data;//{count, rows}
}


export async function getPresenca(id, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${PRESENCA_URL}${id}`, { headers });
    return response.data;
}

export async function savePresenca(id, newPresenca, token) {
    const headers = { 'authorization': token };
    let response;
    if (id)
        response = await axios.patch(`${PRESENCA_URL}${id}`, newPresenca, { headers });
    else
        response = await axios.post(PRESENCA_URL, newPresenca, { headers });
    return response.data;
}

export async function deletePresenca(id, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${PRESENCA_URL}${id}`, { headers });
    return response.data;
}

function thirtyDaysAgo() {  
    const date = new Date();
    date.setDate(date.getDate() - 30);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}

function getStartToday() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date.getTime();
}

function getToday() {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date.getTime();
}

export async function getReportPresenca(eventId, tipoGrafico, startDate, endDate, token) {
    startDate = startDate ? startDate.getTime() : getToday();//thirtyDaysAgo();
    endDate = endDate ? endDate.getTime() : getToday();

    const headers = { 'authorization': token };
   
    const reportUrl = `${PRESENCA_URL}reports/${eventId}?tipoGrafico=${tipoGrafico}&startDate=${startDate}&endDate=${endDate}`;
    const response = await axios.get(reportUrl, { headers });
    return response.data;
}
