import axios from './BaseService';

const ESTADOS_URL = `${process.env.REACT_APP_API_URL}/estados`;


export async function getEstados(page, token) {
    const estadosUrl = `${ESTADOS_URL}?page=${page}`;

    const headers = { 'authorization': token };
    const response = await axios.get(estadosUrl, { headers });
    return response.data;//{count, rows}
}

export async function getEstadosAll( token) {
    const estadosUrl = `${ESTADOS_URL}`;

    const headers = { 'authorization': token };
    const response = await axios.get(estadosUrl, { headers });
    return response.data;//{count, rows}
}

export async function getEstado(uf, token) {
    const headers = { 'authorization': token };
    const response = await axios.get(`${ESTADOS_URL}${uf}`, { headers });
    return response.data;
}

export async function saveEstado(uf, newEstado, token) {
    const headers = { 'authorization': token };
    let response;
    if (uf)
        response = await axios.patch(`${ESTADOS_URL}${uf}`, newEstado, { headers });
    else
        response = await axios.post(ESTADOS_URL, newEstado, { headers });
    return response.data;
}

export async function deleteEstado(uf, token) {
    const headers = { 'authorization': token };
    const response = await axios.delete(`${ESTADOS_URL}${uf}`, { headers });
    return response.data;
}  
